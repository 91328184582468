import './DeletePopup.css';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';

import CircleButton from './CircleButton';
import DialogTitle from './DialogTitle';
import FormButton from './FormButton';

export default function DeletePopup(props) {
  const [open, setOpen] = useState(false)
  const [disableDeleteButton, setDisableDeleteButton] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const deleteItem = () => {
    setOpen(false)
    props.onDelete()
  }

  const handleDeleteConfirm = e => {
    const value = e.target.value
    if (value === props.name) {
      setDisableDeleteButton(false)
    }
  }

  const impactedServiceItems = Object.keys(props.impactedServices).map(key => {
    let serviceCount = props.impactedServices[key]
    return (
      <div className="modal-impacted">
        {serviceCount} {key}
      </div>
    )
  })

  return (
    <div>
      <CircleButton className="red" iconName="times" onClick={handleOpen} />
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Delete {props.name}?
        </DialogTitle>
        <MuiDialogContent dividers>
          <Typography gutterBottom>
            Are you sure? We can't recover something after it's been deleted, so be certain! This will delete the{" "}
            <strong>{props.name}</strong> {props.type}, and other associated services!
          </Typography>
          {props.impactedServices && (
            <div>
              <h3>Impacted Services:</h3>
              {impactedServiceItems}
            </div>
          )}
          <div className="input-label">
            <Typography gutterBottom>Type the name of this account to confirm.</Typography>
          </div>
          <input name="delete_disabled" className="account-name-input" onChange={handleDeleteConfirm} />
        </MuiDialogContent>
        <FormButton
          icon="exclamation-triangle"
          disabled={disableDeleteButton}
          className="btn-red"
          iconName="times"
          onClick={deleteItem}
        >
          Yes, delete the {props.name} {props.type}, I understand the consequences!
        </FormButton>
      </Dialog>
    </div>
  )
}
