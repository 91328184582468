import "./FormInput.css"

import PropTypes from "prop-types"
import React, { Component } from "react"

class FormInput extends Component {
  render() {
    return (
      <div className={"form-input " + this.props.className}>
        <div className="form-input-background" />
        <div className="form-input-container">
          <input
            name={this.props.name}
            type={this.props.type || "text"}
            disabled={this.props.disabled}
            value={this.props.value}
            onChange={this.props.onChange}
            onKeyUp={this.props.onKeyUp}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            readOnly={this.props.readOnly}
          />
        </div>
        <p className="form-input-info">{this.props.info}</p>
      </div>
    )
  }
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  info: PropTypes.string.isRequired,
  type: PropTypes.string,
  pattern: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,
}

export default FormInput
