import '../css/table.css';
import './ProviderForm.css';

import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setNavigation, setProvider } from '../actions';
import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
  }
}

class ConnectedProviderForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      btn_classes: "save",
      btnText: "",
      btnAction: null,
      header: "",
      f_name: "",
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("ProviderForm.componentWillMount")
    if (this.props.computedMatch.params.providerUuid) {
      this.props.setNavigation({ provider: true })
      this.setState({ type: "edit", header: "", btnText: "Update", btnAction: this.handleUpdate })
      let providerUuid = this.props.computedMatch.params.providerUuid
      this.loadProvider(providerUuid)
    } else {
      this.props.setNavigation({})
      this.setState({ type: "create", header: "New", btnText: "Create", btnAction: this.handleCreate })
    }
  }

  loadProvider = providerUuid => {
    console.log(`ProviderForm.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
      self.setState({ header: provider.name, f_name: provider.name })
    })
  }

  handleCreate = event => {
    console.log("ProviderForm.handleCreate")
    this.setState({ processing: true, btn_classes: "fa-spinner fa-pulse" })
    if (this.state.f_name) {
      let name = this.state.f_name
      console.log(`Creating Provider >> Name: ${name}`)
      this.foreman.create_provider(name).then(provider => {
        this.props.history.push({
          pathname: `/provider/${provider.uuid}`,
        })
      })
    } else {
      console.error("ProviderForm.handleCreate empty name")
    }
  }

  handleUpdate = event => {
    console.log("ProviderForm.handleUpdate")
    let providerUuid = this.props.computedMatch.params.providerUuid
    this.setState({ processing: true, btn_classes: "fa-spinner fa-pulse" })
    if (this.state.f_name) {
      let name = this.state.f_name
      console.log(`Updating Provider >> Provider: ${providerUuid} Name: ${name}`)
      this.foreman.update_provider(providerUuid, name).then(() => {
        this.props.history.push({
          pathname: `/provider/${providerUuid}`,
        })
      })
    } else {
      console.error("ProviderForm.handleUpdate empty name")
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  render() {
    return (
      <div id="provider-form">
        <div className="main-header">
          <div className="header">
            <h1>{this.state.header}</h1>
            <h2>Provider</h2>
          </div>
        </div>
        <div className="form">
          <FormInput info="Name" name="f_name" value={this.state.f_name} onChange={this.handleInputChange} />
          <div className="form-actions">
            <div className="form-fill" />
            <FormButton
              text={this.state.btnText}
              icon={this.state.btn_classes}
              disabled={this.state.processing}
              onClick={this.state.btnAction}
            />
          </div>
        </div>
      </div>
    )
  }
}

const ProviderForm = connect(
  null,
  mapDispatchToProps,
)(ConnectedProviderForm)
export default withRouter(ProviderForm)
