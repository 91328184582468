import {
  SET_NAVIGATION,
  SET_ADMIN,
  ADD_PROVIDER,
  SET_PROVIDER,
  SET_ACCOUNT,
  SET_BROADCAST_GROUP,
  SET_USER,
} from "../constants/action-types"

const initialState = {
  navigation: { provider: false, account: false, user: false, broadcastGroup: false },
  admin: { name: "" },
  provider: { uuid: "", name: "" },
  providers: {},
  account: { uuid: "", name: "" },
  user: { uuid: "", name: "", email: "", resources: { numbers: [] } },
  broadcastGroup: { uuid: "", name: "", resources: { numbers: [] } },
}

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAVIGATION:
      return { ...state, navigation: { ...initialState.navigation, ...action.payload } }
    case SET_PROVIDER: {
      let provider = action.payload
      return { ...state, provider: provider }
    }
    case SET_ADMIN: {
      let admin = action.payload
      return { ...state, admin: admin }
    }
    case ADD_PROVIDER: {
      let provider = action.payload
      return { ...state, providers: { ...state.providers, [provider.uuid]: provider } }
    }
    case SET_ACCOUNT: {
      let provider = state.provider
      let account = action.payload
      if (state.provider.uuid !== account.provider) {
        provider = { uuid: "", name: "" }
      }
      return { ...state, provider: provider, account: account }
    }
    case SET_BROADCAST_GROUP: {
      let provider = state.provider
      let account = state.account
      let broadcastGroup = action.payload
      if (state.provider.uuid !== broadcastGroup.provider) {
        provider = { uuid: "", name: "" }
      }
      if (state.account.uuid !== broadcastGroup.account) {
        account = { uuid: "", name: "" }
      }
      return { ...state, provider: provider, account: account, broadcastGroup: broadcastGroup }
    }
    case SET_USER: {
      let provider = state.provider
      let account = state.account
      let user = action.payload
      if (state.provider.uuid !== user.provider) {
        provider = { uuid: "", name: "" }
      }
      if (state.account.uuid !== user.account) {
        account = { uuid: "", name: "" }
      }
      return { ...state, provider: provider, account: account, user: user }
    }
    default: {
      return state
    }
  }
}

export default rootReducer
