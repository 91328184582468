import './index.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarPlus,
  faEnvelope,
  faExclamationTriangle,
  faFileCsv,
  faFingerprint,
  faMinus,
  faMobile,
  faMobileAlt,
  faPencilAlt,
  faPlus,
  faSave,
  faSignOutAlt,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Foreman from 'foreman-api';
import LogRocket from 'logrocket';
import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { setAdmin } from './actions';
import Auth from './auth/Auth';
import Navigation from './components/Navigation';
import PrivateRoute from './PrivateRoute';
import AccountDetail from './views/AccountDetail';
import AccountDetailsReport from './views/AccountDetailsReport';
import AccountForm from './views/AccountForm';
import AccountSummary from './views/AccountSummary';
import BandwidthCallbackDetail from './views/BandwidthCallbackDetail';
import BroadcastGroupDetail from './views/BroadcastGroupDetail';
import BroadcastGroupForm from './views/BroadcastGroupForm';
import MessageAudit from './views/MessageAudit';
import NumberForm from './views/NumberForm';
import ProviderDetail from './views/ProviderDetail';
import ProviderForm from './views/ProviderForm';
import ProviderList from './views/ProviderList';
import UserDetail from './views/UserDetail';
import UserForm from './views/UserForm';
import WebexAccount from './views/WebexAccount';

library.add(
  faFingerprint,
  faEnvelope,
  faMobile,
  faMobileAlt,
  faPencilAlt,
  faPlus,
  faMinus,
  faSave,
  faCalendarPlus,
  faSignOutAlt,
  faExclamationTriangle,
  faTimes,
  faSpinner,
  faFileCsv,
)

const mapStateToProps = state => {
  return {
    admin: state.admin,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setAdmin: admin => dispatch(setAdmin(admin)),
  }
}

class ConnectedApp extends React.Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
    this.state = {
      isAuthenticated: this.foreman.is_authenticated(),
      provider: {},
    }
    this.authenticate = this.authenticate.bind(this)
    this.signout = this.signout.bind(this)
  }

  componentWillMount() {
    window.addEventListener("focus", event => {
      window.hasfocus = true
    })
    window.addEventListener("blur", event => {
      window.hasfocus = false
    })
    if (this.foreman.is_authenticated()) {
      this.load()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.admin !== prevProps.admin) {
      let admin = this.props.admin
      console.log("Identity Set")
      LogRocket.identify(admin.uuid, {
        name: admin.name,
        email: admin.email,
        provider: admin.provider,
        account: admin.account,
      })
    }
  }

  authenticate(email, password, cb) {
    console.log("App.authenticate")
    let self = this
    this.foreman
      .login(email, password)
      .then(() => {
        self.load()
        cb(true, [])
      })
      .catch(error => {
        cb(false, [error])
        self.setState(() => ({ isAuthenticated: false }))
      })
  }

  load = () => {
    console.log("App.load")
    let self = this
    self.foreman.get_current_user().then(user => {
      self.props.setAdmin(user)
      self.setState(() => ({
        isAuthenticated: true,
      }))
    })
  }

  signout() {
    console.log("App.signout")
    this.setState(() => ({
      isAuthenticated: false,
    }))
  }

  render() {
    return (
      <Router>
        <div id="admin">
          {this.state.isAuthenticated ? <Navigation /> : null}
          <Switch>
            <Route
              path="/auth"
              render={() => <Auth authenticate={this.authenticate} isAuthenticated={this.state.isAuthenticated} />}
            />
            <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={WebexAccount} path="/webex-account" />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={UserForm}
              path="/account/:accountUuid/user/new"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={UserForm}
              path="/user/:userUuid/edit"
            />
            <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={UserDetail} path="/user/:userUuid" />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={BroadcastGroupForm}
              path="/account/:accountUuid/broadcast-group/new"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={BroadcastGroupForm}
              path="/broadcast-group/:broadcastGroupUuid/edit"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={BroadcastGroupDetail}
              path="/broadcast-group/:broadcastGroupUuid"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={NumberForm}
              path="/account/:accountUuid/number/new"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={AccountForm}
              path="/account/:accountUuid/edit"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={AccountDetail}
              path="/account/:accountUuid"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={BandwidthCallbackDetail}
              path="/callbacks/bandwidth/:callbackUuid"
            />
            <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={ProviderForm} path="/provider/new" />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={AccountForm}
              path="/provider/:providerUuid/account/new"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              provider={this.state.provider}
              component={MessageAudit}
              path="/provider/:providerUuid/message-audit"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              provider={this.state.provider}
              component={AccountSummary}
              path="/provider/:providerUuid/account-summary"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              provider={this.state.provider}
              component={AccountDetailsReport}
              path="/provider/:providerUuid/account/:accountUuid/account-details-report"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={ProviderForm}
              path="/provider/:providerUuid/edit"
            />
            <PrivateRoute
              isAuthenticated={this.state.isAuthenticated}
              component={ProviderDetail}
              path="/provider/:providerUuid"
            />
            <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={ProviderList} path="/providers" />
            <PrivateRoute isAuthenticated={this.state.isAuthenticated} component={ProviderList} path="/" />
          </Switch>
        </div>
      </Router>
    )
  }
}

const App = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedApp)
export default App
