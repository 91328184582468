import "./LoadingIcon.css"

import React, { Component } from "react"

class LoadingIcon extends Component {
  render() {
    return (
      <div className="loading-container">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  }
}

export default LoadingIcon
