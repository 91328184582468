import '../css/table.css';
import './ProviderDetail.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setNavigation, setProvider } from '../actions';
import bandwidthIcon from '../bandwidth-icon.png';
import CircleButton from '../components/CircleButton';
import FormButton from '../components/FormButton';

var moment = require("moment")

const mapStateToProps = state => {
  return {
    provider: state.provider,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
  }
}

class ConnectedProviderDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      callbacks: {},
      accounts: {},
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("ProviderDetail.componentWillMount")
    this.props.setNavigation({ provider: true })
    let providerUuid = this.props.computedMatch.params.providerUuid
    this.loadProvider(providerUuid)
  }

  componentDidUpdate(prevProps) {
    console.log("ProviderDetail.componentDidUpdate")
    if (this.props.provider.uuid !== prevProps.provider.uuid) {
      this.loadAccounts()
      this.loadBandwidthCallbacks()
      this.loadTelnyxCallbacks()
    }
  }

  handleUrlClick = url => {
    console.log(url)
    this.props.history.push({
      pathname: url,
    })
  }

  loadProvider = providerUuid => {
    console.log(`ProviderDetail.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
      self.loadAccounts()
      self.loadBandwidthCallbacks()
      self.loadTelnyxCallbacks()
    })
  }

  loadAccounts = () => {
    console.log(`ProviderDetail.loadAccounts()`)
    if (this.props.provider.uuid === "") {
      return
    }
    let self = this
    this.foreman.get_accounts(self.props.provider.uuid).then(retAccounts => {
      let accounts = {}
      for (let account of retAccounts) {
        accounts[account.uuid] = account
      }
      self.setState({ accounts: accounts })
    })
  }

  loadBandwidthCallbacks = () => {
    console.log(`ProviderDetail.loadBandwidthCallbacks()`)
    if (this.props.provider.uuid === "") {
      return
    }
    let self = this
    this.foreman.get_bandwidth_callbacks(self.props.provider.uuid).then(retCallbacks => {
      let callbacks = {}
      for (let callback of retCallbacks) {
        callbacks[callback.uuid] = callback
      }
      self.setState({ callbacks: callbacks })
    })
  }

  loadTelnyxCallbacks = () => {
    console.log(`ProviderDetail.loadTelnyxCallbacks()`)
    if (this.props.provider.uuid === "") {
      return
    }
    let self = this
    this.foreman.get_telnyx_callbacks(self.props.provider.uuid).then(retCallbacks => {
      let callbacks = {}
      for (let callback of retCallbacks) {
        callbacks[callback.uuid] = callback
      }
      self.setState({ callbacks: callbacks })
    })
  }

  render() {
    let self = this
    let provider = this.props.provider
    let providerUuid = this.props.computedMatch.params.providerUuid
    let accounts = Object.keys(this.state.accounts).map((key, idx) => {
      let account = self.state.accounts[key]
      return (
        <div
          key={account.uuid}
          className="tbl-row"
          onClick={() => {
            this.handleUrlClick(`/account/${account.uuid}`)
          }}
        >
          <div className="tbl-cell">{account.name}</div>
        </div>
      )
    })
    let callbacks = Object.keys(this.state.callbacks).map((key, idx) => {
      let callback = self.state.callbacks[key]
      return (
        <div
          key={callback.uuid}
          className="tbl-row"
          onClick={() => {
            this.handleUrlClick(`/callbacks/bandwidth/${callback.uuid}`)
          }}
        >
          <div className="tbl-cell">
            <img src={bandwidthIcon} className="callback-icon" alt="Bandwidth" /> {callback.uuid}
          </div>
        </div>
      )
    })

    return (
      <div id="provider">
        <div className="main-header">
          <div className="header">
            <h1>{provider.name}</h1>
            <h2>Provider</h2>
          </div>
						<div style={{ display: "flex", alignItems: "center" }}>
							<FormButton
								text="Message Audit"
								className="btn-small"
								onClick={() => {
									this.handleUrlClick(`/provider/${providerUuid}/message-audit`)
								}}
							/>
							<FormButton
								text="Account Summary"
								className="btn-small"
								onClick={() => {
									this.handleUrlClick(`/provider/${providerUuid}/account-summary`)
								}}
							/>
							<CircleButton
								className="red"
								iconName="pencil-alt"
								onClick={() => {
									this.handleUrlClick(`/provider/${providerUuid}/edit`)
								}}
							/>
					</div>
        </div>
        {/* Details */}
        <div className="details">
          <div className="detail id">
            <FontAwesomeIcon icon="fingerprint" /> {provider.uuid}
          </div>
          <div className="detail created">
            <FontAwesomeIcon icon="calendar-plus" /> {moment(provider.created).calendar()}
          </div>
        </div>
        {/* Accounts */}
        <div className="section">
          <div className="main-header">
            <h1>Accounts</h1>
            <CircleButton
              iconName="plus"
              onClick={() => {
                this.handleUrlClick(`/provider/${providerUuid}/account/new`)
              }}
            />
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                </div>
              </div>
              <div className="tbl-body">{accounts}</div>
            </div>
          </div>
        </div>
        {/* Callbacks */}
        <div className="section">
          <div className="main-header">
            <h1>Callbacks</h1>
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                </div>
              </div>
              <div className="tbl-body">{callbacks}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const ProviderDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedProviderDetail)
export default withRouter(ProviderDetail)
