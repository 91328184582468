import '../css/table.css';
import './ProviderList.css';

import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setNavigation } from '../actions';
import CircleButton from '../components/CircleButton';

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
  }
}

class ConnectedProviderList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      providerUuid: this.props.providerUuid,
      providers: {},
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentDidMount() {
    console.log("ProviderList.componentDidMount")
    this.props.setNavigation({})
    this.loadProviders()
  }

  handleProviderClick = providerUuid => () => {
    this.props.history.push({
      pathname: `/provider/${providerUuid}`,
    })
  }

  handleNewProviderClick = () => {
    this.props.history.push({
      pathname: `/provider/new`,
    })
  }

  loadProviders = () => {
    console.log(`ProviderList.loadProviders()`)
    let self = this
    this.foreman.get_providers().then(retProviders => {
      let providers = {}
      for (let provider of retProviders) {
        providers[provider.uuid] = provider
      }
      if (Object.keys(providers).length === 1) {
        let provider = Object.values(providers)[0]
        self.props.history.push({
          pathname: `/provider/${provider.uuid}`,
        })
      }
      self.setState({ providers: providers })
    })
  }

  render() {
    let self = this
    let providers = Object.keys(this.state.providers).map((key, idx) => {
      let provider = self.state.providers[key]
      return (
        <div key={provider.uuid} className="tbl-row" onClick={self.handleProviderClick(provider.uuid)}>
          <div className="tbl-cell">{provider.name}</div>
        </div>
      )
    })

    return (
      <div id="providers">
        <div className="main-header">
          <h1>Providers</h1>
          <CircleButton iconName="plus" onClick={this.handleNewProviderClick} />
        </div>
        <div>
          <div className="tbl">
            <div className="tbl-header">
              <div className="tbl-row">
                <div className="tbl-cell">Name</div>
              </div>
            </div>
            <div className="tbl-body">{providers}</div>
          </div>
        </div>
      </div>
    )
  }
}

const ProviderList = connect(
  null,
  mapDispatchToProps,
)(ConnectedProviderList)
export default withRouter(ProviderList)
