import './ReportView.css';

import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import {
  DataGrid,
	GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@material-ui/data-grid';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import Foreman from 'foreman-api';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider } from '../actions';
import LoadingIcon from '../components/LoadingIcon';
import { v4 as uuidv4 } from 'uuid';
import { endOfDay } from 'date-fns';

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
  }
}

const ReportView = props => {
  const [provider, setProvider] = useState({})
  const [dateRange, setDateRange] = useState([null, null])
  const [fetchingData, setFetchingData] = useState(true)
  const [durationFilter, setDurationFilter] = useState(props.durationFilter)
  const [filteredData, setFilteredData] = useState(undefined)
  const [rawData, setRawData] = useState(undefined)
  const [providerName, setProviderName] = useState(props.providerId)

  const foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)

  const fetchData = () => {
    setFetchingData(true)
    const param = props.fetchParam || props.providerId
    foreman[props.fetchUrl](param).then(data => {
      setFetchingData(false)
			const formattedData = data.map(item => {
				return {
					id: uuidv4(),
					...item,
				}
			});
      setRawData(formattedData)
      setFilteredData(formattedData)
    })
  }

  useEffect(() => {
    foreman.get_provider(props.providerId).then(provider => {
      setProvider(provider)
      setProviderName(provider.name.split(" ").join("_"))
    })
    fetchData()
  }, [])

  useEffect(
    () => {
      if (rawData) {
        const start = new Date(dateRange[0])
        const end = endOfDay(new Date(dateRange[1]))
        const filteredData = rawData.filter(x => {
          const messageDate = new Date(x.date)
          return messageDate >= start && messageDate <= end
        })
        setFilteredData(filteredData)
      }
    },
    [dateRange],
  )

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
				<GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ fileName: `${providerName}_${props.fileName}` }} />
      </GridToolbarContainer>
    )
  }

  const filterDates = dateValue => {
    setDurationFilter(dateValue)
    const dateRange = props.filterMap[dateValue.value]
    if (dateRange === props.filterMap.all) {
      setFilteredData(rawData)
      return
    }
    if (dateRange === props.filterMap.custom) {
      return
    }
    const filterDate = new Date().setDate(new Date().getDate() - dateRange)
    const filteredData = rawData.filter(x => new Date(x.date) >= filterDate)
    setFilteredData(filteredData)
  }

	// Extra two to account for borders
	const minWidth = props.columns.reduce((prev, curr) => prev += curr.width, 0 ) + 2;

  return (
    <div id="report-view" style={{minWidth: minWidth}}>
      <div className="main-header">
        <div className="header">
          <h1>{props.headerType === "Account" ? props.accountName : provider.name}</h1>
          <h2>{props.headerType}</h2>
        </div>
      </div>
      <div className="header header-btns">
        <h1>{props.title}</h1>
      </div>
      {props.dropDownOptions && (
        <div className="filter-row">
          <TextField
            select
            label="Filter by Duration"
            className="filter-dropwdown"
            value={durationFilter}
            onChange={e => {
              filterDates(e.target.value)
            }}
          >
            {props.dropDownOptions.map(option => (
              <MenuItem key={option.value} value={option}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          {durationFilter.value === "custom" && (
            <div className="report-date-picker">
              <DateRangePicker
                startText="Start"
                endText="End"
                value={dateRange}
                onChange={setDateRange}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField {...startProps} />
                    <Box sx={{ mx: 2 }}> to </Box>
                    <TextField {...endProps} />
                  </React.Fragment>
                )}
              />
            </div>
          )}
        </div>
      )}
      {fetchingData && <LoadingIcon />}
      {!fetchingData &&
        filteredData && (
          <div style={{ display: "flex", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                autoHeight
                rows={filteredData}
                columns={props.columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                pagination
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </div>
          </div>
        )}
    </div>
  )
}

const ReportViewComp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportView)
export default withRouter(ReportViewComp)
