import React, { Component } from 'react';
import logo from '../../logo.png';
import './Header.css';


class Header extends Component {
  render() {
    return (
      <div id='header'>
        <span className="brand">
          <img src={logo} alt="Operator Manager" /> Operator Manager
        </span>
      </div>
    );
  }
}

export default Header;
