import './index.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendarPlus,
  faEnvelope,
  faExclamationTriangle,
  faFileCsv,
  faFingerprint,
  faMobile,
  faMobileAlt,
  faPencilAlt,
  faPlus,
  faSave,
  faSignOutAlt,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import LogRocket from 'logrocket';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';

library.add(
  faFingerprint,
  faEnvelope,
  faMobile,
  faMobileAlt,
  faPencilAlt,
  faPlus,
  faSave,
  faCalendarPlus,
  faSignOutAlt,
  faExclamationTriangle,
  faTimes,
  faSpinner,
  faFileCsv,
)

class BaseLayout extends React.Component {
  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Provider store={store}>
          <App />
        </Provider>
      </LocalizationProvider>
    )
  }
}

if (process.env.NODE_ENV.startsWith("production")) {
  LogRocket.init("aphp5b/crew-admin-portal")
} else {
  console.info(`NODE_ENV: ${process.env.NODE_ENV}`)
  console.info(`REACT_APP_FOREMAN_API_URI: ${process.env.REACT_APP_FOREMAN_API_URI}`)
}

ReactDOM.render(<BaseLayout />, document.getElementById("root"))
