import React from 'react';
import { Redirect } from 'react-router-dom'


class PrivateRoute extends React.Component {
  render() {
    const Component = this.props.component
    if (this.props.isAuthenticated === true) {
      return (
        <div className="main">
          <Component {...this.props} />
        </div>
      )
    } else {
      sessionStorage.setItem('referrer', this.props.location.pathname)
      return <Redirect to={{ pathname: '/auth' }} />
    }
  }
}

export default PrivateRoute;
