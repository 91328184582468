import React, { Component } from "react"
import logo from "../logo.png"
import "./Brand.css"

class Brand extends Component {
  render() {
    let imgClassName = ""
    if (this.props.onClick) {
      imgClassName = "clickable"
    }
    return (
      <div className="brand">
        <img src={logo} alt="Crew" className={imgClassName} onClick={this.props.onClick} />
      </div>
    )
  }
}

export default Brand
