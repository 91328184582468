import './WebexPopup.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import React, { useState } from 'react';

import OperatorLogo from '../operator-logo.svg';
import WebexColor from '../webex-color.svg';
import WebexGray from '../webex-gray.svg';
import DialogTitle from './DialogTitle';
import FormButton from './FormButton';

export default function WebexPopup(props) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <div className="modal">
      <img
        className="modal-trigger"
        src={props.isConnected ? WebexColor : WebexGray}
        onClick={handleOpen}
        alt="Webex teams logo"
      />
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} />
        <MuiDialogContent dividers>
          <div id="branding">
            <img src={OperatorLogo} alt="Operator Logo" /> Operator
            <FontAwesomeIcon icon="plus" />
            <img src={WebexColor} alt="WebEx Teams Logo" /> WebEx
          </div>
          <p>
            Operator Messenger Services has created an easy, secure and reliable integration for you to link your WebEx
            Services. This will bring texts from your Operator numbers to your WebEx account, streamlining the process
            and allowing you to text your clients either as a direct message or in a broadcast group. By clicking
            Connect to WebEx you:
          </p>
          <ul>
            <li>Authorize Operator to utilize your WebEx account information to link your accounts.</li>
            <li>Agree to all terms of use, privacy policies and conditions for both Operator and WebEx.</li>
          </ul>
        </MuiDialogContent>
        <div className="modal-footer">
          <FormButton
            text="Connect to Webex"
            onClick={() => {
              props.onConfirm()
              handleClose()
            }}
          />
          <FormButton text="Cancel" onClick={handleClose} />
        </div>
      </Dialog>
    </div>
  )
}
