import '../css/table.css';
import './AccountDetail.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider, setUser } from '../actions';
import CircleButton from '../components/CircleButton';
import DeletePopup from '../components/DeletePopup';
import FormButton from '../components/FormButton';
import webexColor from '../webex-color.svg';
import webexGray from '../webex-gray.svg';

var moment = require("moment")

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
    setUser: user => dispatch(setUser(user)),
  }
}

class ConnectedAccountDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delete_disabled: true,
      users: {},
      broadcastGroups: {},
      numbers: {},
      accountUuid: props.computedMatch.params.accountUuid,
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentDidMount() {
    console.log("AccountDetail.componentDidMount")
    let accountUuid = this.props.computedMatch.params.accountUuid
    this.props.setNavigation({ provider: true, account: true })
    this.loadAccount(accountUuid)
    this.loadUsers(accountUuid)
    this.loadGroups(accountUuid)
    this.loadNumbers(accountUuid)
  }

  handleNewBroadcastGroupClick = event => {
    console.log("AccountDetail.handleNewUserClick")
    this.props.history.push({
      pathname: `/account/${this.props.account.uuid}/broadcast-group/new`,
    })
  }

  handleBroadcastGroupClick = broadcastGroupUuid => event => {
    console.log("AccountDetail.handleBroadcastGroupClick")
    this.props.history.push({
      pathname: `/broadcast-group/${broadcastGroupUuid}`,
    })
  }

  handleNewUserClick = event => {
    console.log("AccountDetail.handleNewUserClick")
    this.props.history.push({
      pathname: `/account/${this.props.account.uuid}/user/new`,
    })
  }

  handleUserClick = userUuid => event => {
    console.log("AccountDetail.handleUserClick")
    this.props.history.push({
      pathname: `/user/${userUuid}`,
    })
  }

  handleNewNumberClick = event => {
    console.log("AccountDetail.handleNewNumberClick")
    this.props.history.push({
      pathname: `/account/${this.props.account.uuid}/number/new`,
    })
  }

  handleRemoveNumberClick = (cc, number) => {
    console.log("AccountDetail.handleRemoveNumberClick")
    this.foreman.delete_number(cc, number).then(() => {
      this.loadUsers(this.props.account.uuid)
      this.loadGroups(this.props.account.uuid)
      this.loadNumbers(this.props.account.uuid)
    })
  }

  handleEditClick = event => {
    console.log("AccountDetail.handleEditClick")
    let accountUuid = this.props.computedMatch.params.accountUuid
    this.props.history.push({
      pathname: `/account/${accountUuid}/edit`,
    })
  }

  handleDeleteConfirm = event => {
    console.log("AccountDetail.handleDeleteConfirm")
    let value = event.target.value
    this.setState({ delete_disabled: value !== this.props.account.name })
  }

  handleDeleteAccount = () => {
    console.log("AccountDetail.handleDeleteAccount")
    let accountUuid = this.props.computedMatch.params.accountUuid
    this.foreman.delete_account(accountUuid).then(() => {
      this.props.history.push({
        pathname: `/provider/${this.props.provider.uuid}`,
      })
    })
  }

  loadProvider = providerUuid => {
    console.log(`BroadcastGroupDetail.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`AccountDetail.loadAccount(${accountUuid})`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      self.setState({ isConnected: !!account.webex_admin })
      if (account.provider !== self.props.provider.uuid) {
        self.loadProvider(account.provider)
      }
    })
  }

  loadUsers = accountUuid => {
    console.log(`AccountDetail.loadUsers(${accountUuid})`)
    let self = this
    this.foreman.get_users(accountUuid).then(retUsers => {
      let users = self.state.users
      for (let user of retUsers) {
        users[user.uuid] = user
      }
      self.setState({ users: users })
    })
  }

  loadGroups = accountUuid => {
    console.log(`AccountDetail.loadGroups(${accountUuid})`)
    let self = this
    this.foreman.get_broadcast_groups(accountUuid).then(retBroadcastGroups => {
      let broadcastGroups = self.state.broadcastGroups
      for (let bgroup of retBroadcastGroups) {
        broadcastGroups[bgroup.uuid] = bgroup
      }
      self.setState({ broadcastGroups: broadcastGroups })
    })
  }

  loadNumbers = accountUuid => {
    console.log(`AccountDetail.loadNumbers(${accountUuid})`)
    let self = this
    this.foreman.get_account_numbers(accountUuid).then(retNumbers => {
      let numbers = {}
      for (let number of retNumbers) {
        numbers[`${number.cc}-${number.number}`] = number
      }
      self.setState({ numbers: numbers })
    })
  }

  render() {
    let self = this
    let account = this.props.account ? this.props.account : { name: "" }
    let users = Object.keys(this.state.users).map((key, idx) => {
      let user = self.state.users[key]
      return (
        <div key={user.uuid} className="tbl-row" onClick={self.handleUserClick(user.uuid)}>
          <div className="tbl-cell">{user.name}</div>
          <div className="tbl-cell">{user.email}</div>
          <div className="tbl-cell">
            {user.resources.numbers.map((number, idx) => (
              <div key={idx} className="resource-number">
                <FontAwesomeIcon icon="mobile-alt" />+{number.cc}-{number.number}
              </div>
            ))}
          </div>
        </div>
      )
    })
    let bgroups = Object.keys(this.state.broadcastGroups).map((key, idx) => {
      let bgroup = self.state.broadcastGroups[key]
      return (
        <div key={bgroup.uuid} className="tbl-row">
          <div className="tbl-cell" onClick={self.handleBroadcastGroupClick(bgroup.uuid)}>
            {bgroup.name}
          </div>
          <div className="tbl-cell">
            {bgroup.resources.numbers.map((number, idx) => (
              <div key={idx} className="resource-number">
                <FontAwesomeIcon icon="mobile-alt" />+{number.cc}-{number.number}
              </div>
            ))}
          </div>
        </div>
      )
    })
    const numbers = Object.keys(this.state.numbers).map(key => {
      const number = self.state.numbers[key]
      const owner =
        number.resource.type === "User"
          ? self.state.users[number.resource.uuid]
          : self.state.broadcastGroups[number.resource.uuid]
      return (
        <div key={number.uuid} className="tbl-row number">
          <div className="tbl-cell">
            +{number.cc}-{number.number}
          </div>
          <div className="tbl-cell">{number.resource.type}</div>
          <div className="tbl-cell">{owner ? owner.name : ""}</div>
          <div className="tbl-cell btn-remove">
            <CircleButton
              className="red btn-small"
              iconName="minus"
              onClick={() => this.handleRemoveNumberClick(number.cc, number.number)}
            />
          </div>
        </div>
      )
    })

    return (
      <div id="account">
        <div className="main-header">
          <div className="header">
            <h1>{account.name}</h1>
            <h2>Account</h2>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
						<FormButton
							text="Account Details"
							className="btn-small"
							onClick={() => {
								this.props.history.push({
									pathname: `/provider/${account.provider}/account/${this.state.accountUuid}/account-details-report`,
								})
							}}
						/>
						<CircleButton key="dedit" className="red" iconName="pencil-alt" onClick={this.handleEditClick} />
						<DeletePopup
							name={account.name}
							type="Account"
							onClick
							impactedServices={{
								Users: Object.keys(this.state.users).length,
								"Broadcast Groups": Object.keys(this.state.broadcastGroups).length,
							}}
							onDelete={self.handleDeleteAccount}
						/>
					</div>
        </div>
        {/* Details */}
        <div className="details">
          <div className="detail id">
            <FontAwesomeIcon icon="fingerprint" /> {account.uuid}
          </div>
          <div className="detail created">
            <FontAwesomeIcon icon="calendar-plus" /> {moment(account.created).calendar()}
          </div>
          <div className="detail webex">
            <img src={this.state.isConnected ? webexColor : webexGray} onClick={this.handleEditClick} />
          </div>
        </div>
        <div className="section">
          <div className="main-header">
            <h1>Users</h1>
            <CircleButton iconName="plus" onClick={this.handleNewUserClick} />
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell">E-mail</div>
                  <div className="tbl-cell">Resources</div>
                </div>
              </div>
              <div className="tbl-body">{users}</div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="main-header">
            <h1>Broadcast Groups</h1>
            <CircleButton iconName="plus" onClick={this.handleNewBroadcastGroupClick} />
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell">Resources</div>
                </div>
              </div>
              <div className="tbl-body">{bgroups}</div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="main-header">
            <h1>Numbers</h1>
            <CircleButton iconName="plus" onClick={this.handleNewNumberClick} />
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell">Type</div>
                  <div className="tbl-cell">Owner</div>
                  <div className="tbl-cell" />
                </div>
              </div>
              <div className="tbl-body">{numbers}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const AccountDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedAccountDetail)
export default withRouter(AccountDetail)
