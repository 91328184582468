import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./FormButton.css"

class FormButton extends Component {
  onClick = event => {
    if (!this.props.disabled) {
      this.props.onClick(event)
    }
  }

  render() {
    let className = "form-button " + this.props.className
    if (this.props.disabled) {
      className += " disabled"
    }
    return (
      <div className={className} onClick={this.onClick}>
        <div className="form-button-btn">
          <FontAwesomeIcon icon={this.props.icon} pulse={this.props.pulse} /> {this.props.text} {this.props.children}
        </div>
      </div>
    )
  }
}

FormButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  pulse: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default FormButton
