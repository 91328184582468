import '../css/table.css';
import './BandwidthCallbackDetail.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setNavigation, setProvider } from '../actions';

const mapStateToProps = state => {
  return {
    provider: state.provider,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
  }
}

class ConnectedBandwidthCallbackDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      callback: { uuid: "" },
      numbers: {},
      accounts: {},
      owners: {},
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("BandwidthCallbackDetail.componentWillMount")
    this.props.setNavigation({ provider: true })
    this.loadBandwidthCallback()
    this.loadNumbers()
  }

  componentDidUpdate(prevProps) {
    console.log("BandwidthCallbackDetail.componentDidUpdate")
    if (this.props.provider.uuid !== prevProps.provider.uuid) {
      this.loadBandwidthCallback()
      this.loadNumbers()
    }
  }

  loadProvider = providerUuid => {
    console.log(`BandwidthCallbackDetail.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadBandwidthCallback = () => {
    console.log(`BandwidthCallbackDetail.loadBandwidthCallback()`)
    let self = this
    let callbackUuid = this.props.computedMatch.params.callbackUuid
    this.foreman.get_bandwidth_callback(callbackUuid).then(callback => {
      self.setState({ callback: callback })
      this.loadProvider(callback.provider)
      this.loadOwners(callback.provider)
      this.loadAccounts(callback.provider)
    })
  }

  loadNumbers = () => {
    console.log(`BandwidthCallbackDetail.loadNumbers()`)
    let callbackUuid = this.props.computedMatch.params.callbackUuid
    if (this.props.provider.uuid === "") {
      return
    }
    let self = this
    this.foreman.get_numbers(self.props.provider.uuid, callbackUuid).then(retNumbers => {
      let numbers = {}
      for (let number of retNumbers) {
        numbers[`${number.cc}-${number.number}`] = number
      }
      self.setState({ numbers: numbers })
    })
  }

  loadOwners = async providerUuid => {
    console.log(`BandwidthCallbackDetail.loadOwners(${providerUuid})`)
    const [groups, users] = await Promise.all([
      this.foreman.get_provider_broadcast_groups(providerUuid),
      this.foreman.get_provider_users(providerUuid),
    ])
    let owners = this.state.owners
    for (let group of groups) {
      owners[group.uuid] = group
    }
    for (let user of users) {
      owners[user.uuid] = user
    }
    this.setState({ owners: owners })
  }

  loadAccounts = providerUuid => {
    console.log(`BandwidthCallbackDetail.loadAccounts(${providerUuid})`)
    this.foreman.get_accounts(providerUuid).then(retAccounts => {
      let accounts = {}
      for (let account of retAccounts) {
        accounts[account.uuid] = account
      }
      this.setState({ accounts: accounts })
    })
  }

  render() {
    let callback = this.state.callback
    let self = this
    let numbers = Object.keys(this.state.numbers).map((key, idx) => {
      let number = self.state.numbers[key]
      let account = self.state.accounts[number.account]
      let owner = self.state.owners[number.resource.uuid]
      return (
        <div key={number.uuid} className="tbl-row">
          <div className="tbl-cell">
            +{number.cc}-{number.number}
          </div>
          <div className="tbl-cell">{account ? account.name : ""}</div>
          <div className="tbl-cell">{number.resource.type}</div>
          <div className="tbl-cell">{owner ? owner.name : ""}</div>
        </div>
      )
    })

    return (
      <div id="callback">
        <div className="main-header">
          <div className="header">
            <h1>{callback.uuid}</h1>
            <h2>Bandwidth Callback</h2>
          </div>
        </div>
        {/* Details */}
        <div className="details">
          <div className="detail id">
            <FontAwesomeIcon icon="fingerprint" alt="Callback UUID" /> {callback.uuid}
          </div>
          <div className="detail created">
            <FontAwesomeIcon icon="calendar-plus" alt="Application ID" /> {callback.application_id}
          </div>
        </div>
        {/* Numbers */}
        <div className="section">
          <div className="main-header">
            <h1>Numbers</h1>
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell">Account</div>
                  <div className="tbl-cell">Type</div>
                  <div className="tbl-cell">Owner</div>
                </div>
              </div>
              <div className="tbl-body">{numbers}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const BandwidthCallbackDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedBandwidthCallbackDetail)
export default withRouter(BandwidthCallbackDetail)
