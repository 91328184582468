import Foreman from 'foreman-api';
import React, { Component } from 'react';

class WebexAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      text: "Your WebEx account is being linked...",
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  async componentWillMount() {
    const code = new URLSearchParams(this.props.location.search).get("code")
    const accountUuid = new URLSearchParams(this.props.location.search).get("state")
    const result = await this.foreman.save_webex_account_auth(accountUuid, code)
    this.setState({ text: result.text })
  }

  render() {
    return <h1 style={{ textAlign: "center" }}>{this.state.text}</h1>
  }
}

export default WebexAccount
