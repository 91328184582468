import { format } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider } from '../actions';
import ReportView from '../components/ReportView';
import Foreman from 'foreman-api';

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
  }
}

const columns = [
  {
    field: "name",
    headerName: "Name",
    sortDirection: "desc",
    width: 200,
  },
  { field: "email", headerName: "Email", width: 200 },
  { field: "type", headerName: "Room Type", width: 200 },
  { field: "number", headerName: "Number", width: 200 },
  { field: "created",
		headerName: "Created On",
		width: 200, 
		valueGetter: ({value}) => new Date(value),
		valueFormatter: ({value}) => format(value, 'P p'),
	},
  { field: "messages", headerName: "Total Messages", width: 200 },
]

const searchOptions = {
  keys: ["name", "email", "type", "number", "messages"],
}

const filterMap = {
  thirty: 30,
  sixty: 60,
  ninety: 90,
  custom: "custom",
  all: "all",
}

const dropDownOptions = [
  {
    label: "Past 30 Days",
    value: "thirty",
  },
  {
    label: "Past 60 Days",
    value: "sixty",
  },
  {
    label: "Past 90 Days",
    value: "ninety",
  },
  {
    label: "Custom",
    value: "custom",
  },
  {
    label: "All",
    value: "all",
  },
]
class AccountDetailsReport extends Component {
  constructor(props) {
    super(props)
    const providerId = props.computedMatch.params.providerUuid
    const accountUuid = props.computedMatch.params.accountUuid
    this.state = {
      providerId,
      accountUuid,
      accountName: this.props.account ? this.props.account.name : accountUuid,
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentDidMount() {
    console.log("AccountDetailsReport.componentDidMount")
    this.props.setNavigation({ provider: true, account: true })
    this.loadAccount(this.props.computedMatch.params.accountUuid)
  }

  loadProvider = providerUuid => {
    console.log(`AccountDetailsReport.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`AccountDetailsReport.loadAccount(${accountUuid})`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      if (account.provider !== self.props.provider.uuid) {
        self.loadProvider(account.provider)
      }
    })
  }

  render() {
    return (
      <ReportView
        columns={columns}
        title={"Account Details"}
        filterMap={filterMap}
        dropDownOptions={dropDownOptions}
        durationFilter={dropDownOptions.find(x => x.value === "all")}
        searchOptions={searchOptions}
        fetchUrl={"download_account_report"}
        fetchParam={this.state.accountUuid}
        providerId={this.state.providerId}
        headerType={"Account"}
        fileName={`${this.state.accountName}_Report.csv`}
        accountName={this.state.accountName}
      />
    )
  }
}

const AccountDetailsReportComp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDetailsReport)
export default withRouter(AccountDetailsReportComp)
