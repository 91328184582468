import '../css/table.css';
import './BroadcastGroupDetail.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setBroadcastGroup, setNavigation, setProvider } from '../actions';
import AddFromListPopup from '../components/AddFromListPopup';
import CircleButton from '../components/CircleButton';
import DeletePopup from '../components/DeletePopup';

var moment = require("moment")

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
    broadcastGroup: state.broadcastGroup,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
    setBroadcastGroup: broadcastGroup => dispatch(setBroadcastGroup(broadcastGroup)),
  }
}

class ConnectedBroadcastGroupDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: {},
      users: {},
      availableNumbers: {},
      members: {},
      conversationCount: 0,
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("BroadcastGroupDetail.componentDidMount")
    this.props.setNavigation({ provider: true, account: true, broadcastGroup: true })
    let broadcastGroupUuid = this.props.computedMatch.params.broadcastGroupUuid
    this.loadBroadcastGroup(broadcastGroupUuid)
    this.loadMembers(broadcastGroupUuid)
    this.conversationCount(broadcastGroupUuid)
    if (this.props.account.uuid) {
      this.loadAccountUsers()
      this.loadAvailableAccountNumbers()
    }
  }

  loadProvider = providerUuid => {
    console.log(`BroadcastGroupDetail.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`BroadcastGroupDetail.loadAccount(${accountUuid})`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      if (account.provider !== self.props.provider.uuid) {
        self.loadProvider(account.provider)
      }
      self.loadAccountUsers()
      self.loadAvailableAccountNumbers()
    })
  }

  loadBroadcastGroup = broadcastGroupUuid => {
    console.log(`BroadcastGroupDetail.loadBroadcastGroup(${broadcastGroupUuid})`)
    let self = this
    this.foreman.get_broadcast_group(broadcastGroupUuid).then(broadcastGroup => {
      self.props.setBroadcastGroup(broadcastGroup)
      if (broadcastGroup.account !== self.props.account.uuid) {
        self.loadAccount(broadcastGroup.account)
      }
    })
  }

  loadAccountUsers = () => {
    console.log(`BroadcastGroupDetail.loadAccountUsers()`)
    let self = this
    this.foreman.get_users(this.props.account.uuid).then(retUsers => {
      let users = self.state.users
      for (let user of retUsers) {
        users[user.uuid] = user
      }
      self.setState({ users: users })
    })
  }

  loadAvailableAccountNumbers = () => {
    console.log(`BroadcastGroupDetail.loadAccountNumbers()`)
    let self = this
    this.foreman.get_account_numbers(this.props.account.uuid).then(retNumbers => {
      let numbers = {}
      for (const number of retNumbers) {
        if (number.resource.type === "None") {
          numbers[`+${number.cc}-${number.number}`] = number
        }
      }
      self.setState({ availableNumbers: numbers })
    })
  }

  loadMembers = broadcastGroupUuid => {
    console.log(`BroadcastGroupDetail.loadMembers(${broadcastGroupUuid})`)
    let self = this
    this.foreman.get_broadcast_group_members(broadcastGroupUuid).then(response => {
      let members = {}
      for (let memberUuid of response.users) {
        members[memberUuid] = { uuid: memberUuid, name: memberUuid }
      }
      self.setState({ members: members })

      for (let memberUuid of response.users) {
        self.foreman.get_user(memberUuid).then(user => {
          let members = self.state.members
          members[memberUuid] = user
          self.setState({ members: members })
        })
      }
    })
  }

  conversationCount = broadcastGroupUuid => {
    console.log(`BroadcastGroupDetail.conversationCount(${broadcastGroupUuid})`)
    this.foreman.get_room_conversations(broadcastGroupUuid).then(response => {
      this.setState({ conversationCount: response.length })
    })
  }

  handleUserClick = userUuid => event => {
    console.log("BroadcastGroupDetail.handleUserClick")
    this.props.history.push({
      pathname: `/user/${userUuid}`,
    })
  }

  handleNewResourceClick = numberKeys => {
    console.log("BroadcastGroupDetail.handleNewResourceClick")
    const promises = []
    for (const numberKey of numberKeys) {
      promises.push(this.foreman.set_number_resource(numberKey, this.props.broadcastGroup.uuid, "BroadcastGroup"))
    }
    Promise.all(promises).then(() => {
      this.loadBroadcastGroup(this.props.broadcastGroup.uuid)
      this.loadAvailableAccountNumbers()
    })
  }

  handleRemoveResourceClick = numberKey => {
    console.log("BroadcastGroupDetail.handleNewResourceClick")
    this.foreman.set_number_resource(numberKey, undefined, "None").then(() => {
      this.loadBroadcastGroup(this.props.broadcastGroup.uuid)
      this.loadAvailableAccountNumbers()
    })
  }

  handleNewMemberClick = members => {
    console.log("BroadcastGroupDetail.handleNewMemberClick")
    this.foreman.add_broadcast_group_members(this.props.broadcastGroup.uuid, members).then(() => {
      this.loadMembers(this.props.broadcastGroup.uuid)
    })
  }

  handleRemoveMemberClick = memberUuid => {
    console.log("BroadcastGroupDetail.handleRemoveMemberClick")
    this.foreman.delete_broadcast_group_member(this.props.broadcastGroup.uuid, memberUuid).then(() => {
      this.loadMembers(this.props.broadcastGroup.uuid)
    })
  }

  handleEditClick = event => {
    console.log("BroadcastGroupDetail.handleEditClick")
    this.props.history.push({
      pathname: `/broadcast-group/${this.props.broadcastGroup.uuid}/edit`,
    })
  }

  handleDeleteClick = event => {
    console.log("BroadcastGroupDetail.handleEditClick")
    this.foreman.delete_broadcast_group(this.props.broadcastGroup.uuid).then(() => {
      this.props.history.push({
        pathname: `/account/${this.props.account.uuid}`,
      })
    })
  }

  render() {
    let self = this
    let account = this.state.account
    if (!account) {
      account = { name: "" }
    }
    let resources = this.props.broadcastGroup.resources.numbers.map((number, idx) => (
      <div key={idx} className="tbl-row">
        <div className="tbl-cell resource-number">
          <FontAwesomeIcon icon="mobile-alt" />+{number.cc}-{number.number}
        </div>
        <div className="tbl-cell btn-remove">
          <CircleButton
            className="red btn-small"
            iconName="minus"
            onClick={() => this.handleRemoveResourceClick(`+${number.cc}-${number.number}`)}
          />
        </div>
      </div>
    ))

    const memberUuids = []
    let members = Object.keys(this.state.members).map((key, idx) => {
      let member = self.state.members[key]
      memberUuids.push(member.uuid)
      return (
        <div key={member.uuid} className="tbl-row">
          <div className="tbl-cell" onClick={self.handleUserClick(member.uuid)}>
            {member.name}
          </div>
          <div className="tbl-cell btn-remove">
            <CircleButton
              className="red btn-small"
              iconName="minus"
              onClick={() => this.handleRemoveMemberClick(member.uuid)}
            />
          </div>
        </div>
      )
    })
    const memberOptions = []
    for (const key of Object.keys(this.state.users)) {
      let user = self.state.users[key]
      if (!memberUuids.includes(user.uuid)) {
        memberOptions.push({ name: user.name, value: user.uuid })
      }
    }
    const resourceOptions = []
    for (const key of Object.keys(this.state.availableNumbers)) {
      resourceOptions.push({ name: key, value: key })
    }
    return (
      <div id="broadcast-group">
        {/* Header */}
        <div className="main-header">
          <div className="header">
            <h1>{this.props.broadcastGroup.name}</h1>
            <h2>Broadcast Group</h2>
          </div>
          <CircleButton className="red" iconName="pencil-alt" onClick={this.handleEditClick} />
          <DeletePopup
            name={this.props.broadcastGroup.name}
            type="Broadcast Group"
            impactedServices={{
              Conversations: this.state.conversationCount,
              Resources: resources.length,
            }}
            onDelete={this.handleDeleteClick}
          />
        </div>
        {/* Details */}
        <div className="details">
          <div className="detail id">
            <FontAwesomeIcon icon="fingerprint" /> {this.props.broadcastGroup.uuid}
          </div>
          <div className="detail created">
            <FontAwesomeIcon icon="calendar-plus" /> {moment(this.props.broadcastGroup.created).calendar()}
          </div>
        </div>
        {/* Resources */}
        <div className="section">
          <div className="main-header">
            <h1 className="header">Resources</h1>
            <AddFromListPopup type="Resource" options={resourceOptions} onAdd={this.handleNewResourceClick} />
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell" />
                </div>
              </div>
              <div className="tbl-body">{resources}</div>
            </div>
          </div>
        </div>
        {/* Members */}
        <div className="section">
          <div className="main-header">
            <h1 className="header">Members</h1>
            <AddFromListPopup type="Member" options={memberOptions} onAdd={this.handleNewMemberClick} />
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell" />
                </div>
              </div>
              <div className="tbl-body">{members}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const BroadcastGroupDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedBroadcastGroupDetail)
export default withRouter(BroadcastGroupDetail)
