import '../css/table.css';
import './AccountForm.css';

import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider } from '../actions';
import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';
import WebexPopup from '../components/WebexPopup';

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
  }
}

class ConnectedAccountForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      btn_classes: "save",
      btnText: "",
      btnAction: null,
      header: "",
      f_name: "",
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("AccountForm.componentWillMount")
    let accountUuid = this.props.computedMatch.params.accountUuid
    if (accountUuid) {
      this.props.setNavigation({ provider: true, account: true })
      this.setState({ type: "edit", header: "", btnText: "Update", btnAction: this.handleUpdateAccount })
      this.loadAccount(accountUuid)
    } else {
      let providerUuid = this.props.computedMatch.params.providerUuid
      this.props.setNavigation({ provider: true })
      this.setState({ type: "create", header: "New", btnText: "Create", btnAction: this.handleCreateAccount })
      this.loadProvider(providerUuid)
    }
  }

  loadProvider = providerUuid => {
    console.log(`BroadcastGroupDetail.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`AccountDetail.loadAccount(${accountUuid})`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      self.setState({
        header: account.name,
        f_name: account.name,
        isConnected: !!account.webex_admin,
      })
      if (account.provider !== self.props.provider.uuid) {
        self.loadProvider(account.provider)
      }
    })
  }

  handleCreateAccount = event => {
    console.log("AccountForm.handleCreateAccount")
    this.setState({ processing: true, btn_classes: "spinner" })
    if (this.props.provider && this.state.f_name) {
      let providerUuid = this.props.provider.uuid
      let name = this.state.f_name
      console.log(`Creating Account >> Provider: ${providerUuid} Name: ${name}`)
      this.foreman.create_account(providerUuid, name).then(account => {
        this.props.history.push({
          pathname: `/account/${account.uuid}`,
        })
      })
    } else {
      console.error("AccountForm.handleCreateAccount empty providerUuid or name")
    }
  }

  handleUpdateAccount = event => {
    console.log("AccountForm.handleUpdateAccount")
    let accountUuid = this.props.computedMatch.params.accountUuid
    this.setState({ processing: true, btn_classes: "spinner" })
    if (this.state.f_name) {
      let name = this.state.f_name
      console.log(`Updating Account >> Account: ${accountUuid} Name: ${name}`)
      this.foreman.update_account(accountUuid, name).then(() => {
        this.props.history.push({
          pathname: `/account/${accountUuid}`,
        })
      })
    } else {
      console.error("AccountForm.handleUpdateAccount empty name")
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleWebexAuth = () => {
    const webexLink = `https://webexapis.com/v1/authorize?client_id=Caa2d9f6bcb213359aed1174a122f34db8fc3edc5821b5453b6bc27ff7f8d064b&response_type=code&redirect_uri=https%3A%2F%2Fmanager.operatorapp.io%2Fwebex-account&scope=spark%3Akms%20spark%3Apeople_read%20spark%3Amessages_read%20spark-compliance%3Amessages_read&state=${
      this.props.account.uuid
    }`
    window.open(webexLink, "_blank")
  }

  render() {
    let btnAction = this.handleCreateAccount
    if (this.state.type === "edit") {
      btnAction = this.handleUpdateAccount
    }

    return (
      <div id="account-form">
        <div className="main-header">
          <div className="header">
            <h1>{this.state.header}</h1>
            <h2>Account</h2>
          </div>
          <div className="webex-indicator">
            <WebexPopup
              isConnected={this.state.isConnected}
              name={this.state.f_name}
              onConfirm={this.handleWebexAuth}
            />
          </div>
        </div>
        <div className="form">
          <FormInput info="Name" name="f_name" value={this.state.f_name} onChange={this.handleInputChange} />
          <div className="form-actions">
            <div className="form-fill" />
            <FormButton
              text={this.state.btnText}
              icon={this.state.btn_classes}
              disabled={this.state.processing}
              pulse={this.state.processing}
              onClick={btnAction}
            />
          </div>
        </div>
      </div>
    )
  }
}

const AccountForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedAccountForm)
export default withRouter(AccountForm)
