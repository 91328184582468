import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"

import { addProvider, setProvider } from "../actions/index"

import Foreman from "foreman-api"
import Brand from "./Brand"
import FormSelect from "./FormSelect"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./Navigation.css"

const mapStateToProps = state => {
  return {
    navigation: state.navigation,
    admin: state.admin,
    provider: state.provider,
    providers: state.providers,
    account: state.account,
    user: state.user,
    broadcastGroup: state.broadcastGroup,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addProvider: provider => dispatch(addProvider(provider)),
    setProvider: provider => dispatch(setProvider(provider)),
  }
}

class ConnectedNavigation extends Component {
  constructor(props) {
    super(props)
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
    this.state = {
      provider: this.props.provider,
      providers: this.props.providers,
      account: this.props.account,
      user: this.props.user,
    }
  }

  componentWillMount() {
    this.loadProviders()
  }

  handleClick = type => event => {
    switch (type) {
      case "Home":
        console.log("Home")
        this.props.history.push({
          pathname: `/`,
        })
        break
      case "Provider":
        console.log("Provider")
        this.props.history.push({
          pathname: `/provider/${this.props.provider.uuid}`,
        })
        break
      case "Account":
        console.log("Account")
        this.props.history.push({
          pathname: `/account/${this.props.account.uuid}`,
        })
        break
      case "User":
        console.log("User")
        this.props.history.push({
          pathname: `/user/${this.props.user.uuid}`,
        })
        break
      case "BroadcastGroup":
        console.log("BroadcastGroup")
        this.props.history.push({
          pathname: `/broadcast-group/${this.props.broadcastGroup.uuid}`,
        })
        break
      default:
        console.log("default")
        break
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    if (name === "provider") {
      if (value === "") {
        this.props.setProvider({ uuid: "", name: "" })
        this.props.history.push({
          pathname: `/providers/`,
        })
      } else {
        let provider = this.props.providers[value]
        this.props.setProvider(provider)
        this.props.history.push({
          pathname: `/provider/${provider.uuid}`,
        })
      }
    }
  }

  handleLogout = event => {
    console.log("Navigation.handleLogout")
    this.foreman.logout()
  }

  loadProviders = () => {
    console.log("Navigation.loadProviders")
    let self = this
    this.foreman.get_providers().then(retProviders => {
      for (let provider of retProviders) {
        self.props.addProvider(provider)
      }
    })
  }

  render() {
    let self = this
    let providerNav = null
    if (this.props.navigation.provider && this.props.providers) {
      if (Object.keys(this.props.providers).length > 1) {
        let providerOptions = Object.keys(this.props.providers).map((key, idx) => {
          let provider = self.props.providers[key]
          return { name: provider.name, value: provider.uuid }
        })
        providerNav = (
          <div className="provider-select">
            <FormSelect
              name="provider"
              info="Provider"
              value={this.props.provider.uuid}
              options={providerOptions}
              infoOnClick={this.handleClick("Provider")}
              onChange={this.handleInputChange}
            />
          </div>
        )
      } else {
        providerNav = (
          <div className="navigation-element">
            <div className="navigation-container">
              {this.props.provider.name === "" ? <i className="fa fa-spinner fa-pulse" /> : this.props.provider.name}
            </div>
            <p className="navigation-info">
              <span onClick={this.handleClick("Provider")}>Provider</span>
            </p>
          </div>
        )
      }
    }

    return (
      <div className="navigation">
        <Brand onClick={this.handleClick("Home")} />
        {providerNav}
        {this.props.navigation.account ? (
          <div className="navigation-element">
            <div className="navigation-container">
              {this.props.account.name === "" ? <FontAwesomeIcon icon="spinner" pulse /> : this.props.account.name}
            </div>
            <p className="navigation-info">
              <span onClick={this.handleClick("Account")}>Account</span>
            </p>
          </div>
        ) : null}
        {this.props.navigation.user ? (
          <div className="navigation-element">
            <div className="navigation-container">
              {this.props.user.name === "" ? <FontAwesomeIcon icon="spinner" pulse /> : this.props.user.name}
            </div>
            <p className="navigation-info">
              <span onClick={this.handleClick("User")}>User</span>
            </p>
          </div>
        ) : null}
        {this.props.navigation.broadcastGroup ? (
          <div className="navigation-element">
            <div className="navigation-container">
              {this.props.broadcastGroup.name === "" ? (
                <FontAwesomeIcon icon="spinner" pulse />
              ) : (
                this.props.broadcastGroup.name
              )}
            </div>
            <p className="navigation-info">
              <span onClick={this.handleClick("BroadcastGroup")}>Broadcast Group</span>
            </p>
          </div>
        ) : null}
        <div className="navigation-fill" />
        <div className="navigation-user">
          <div className="admin">{this.props.admin.name}</div>
          <FontAwesomeIcon icon="sign-out-alt" onClick={this.handleLogout} />
        </div>
      </div>
    )
  }
}

ConnectedNavigation.propTypes = {}
const Navigation = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedNavigation)
export default withRouter(Navigation)
