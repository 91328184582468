import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const DialogTitle = props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle className="dialog-title">
      <Typography variant="h6">{children}</Typography>
      {onClose ? <FontAwesomeIcon icon="times" className="close-dialog" onClick={onClose} /> : null}
    </MuiDialogTitle>
  )
}

export default DialogTitle
