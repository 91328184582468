import React, { Component } from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./CircleButton.css"

class CircleButton extends Component {
  render() {
    let className = "circle-button " + this.props.className
    let iconName = this.props.iconName
    return (
      <div className={className} onClick={this.props.onClick} title={this.props.title}>
        <div className="circle-button-btn">
          <FontAwesomeIcon icon={iconName} />
        </div>
      </div>
    )
  }
}

CircleButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default CircleButton
