import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider } from '../actions';
import ReportView from '../components/ReportView';
import Foreman from 'foreman-api';

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
  }
}

const columns = [
  {
    field: "name",
    headerName: "Account Name",
    sortDirection: "desc",
    width: 300,
  },
  { field: "users", headerName: "Number of Users", width: 300 },
  { field: "groups", headerName: "Number of Broadcast Groups", width: 300 },
]

class AccountSummary extends Component {
  constructor(props) {
    super(props)
    const providerId = props.computedMatch.params.providerUuid
    this.state = {
      providerId,
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentDidMount() {
    console.log("MessageAudit.componentDidMount")
    this.props.setNavigation({ provider: true })
    this.loadProvider(this.props.computedMatch.params.providerUuid)
  }

  loadProvider = providerUuid => {
    console.log(`MessageAudit.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  render() {
    return (
      <ReportView
        columns={columns}
        title={"Account Summary"}
        fetchUrl={"download_provider_summary"}
        fileName={"_Summary.csv"}
        minHeight={500}
        providerId={this.state.providerId}
        headerType={"Provider"}
      />
    )
  }
}

const AccountSummaryComp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSummary)
export default withRouter(AccountSummaryComp)
