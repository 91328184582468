import {
  SET_NAVIGATION,
  SET_ADMIN,
  ADD_PROVIDER,
  SET_PROVIDER,
  SET_ACCOUNT,
  SET_BROADCAST_GROUP,
  SET_USER,
} from "../constants/action-types"

export const setNavigation = navigation => ({ type: SET_NAVIGATION, payload: navigation })
export const setAdmin = admin => ({ type: SET_ADMIN, payload: admin })
export const addProvider = provider => ({ type: ADD_PROVIDER, payload: provider })
export const setProvider = provider => ({ type: SET_PROVIDER, payload: provider })
export const setAccount = account => ({ type: SET_ACCOUNT, payload: account })
export const setBroadcastGroup = broadcastGroup => ({ type: SET_BROADCAST_GROUP, payload: broadcastGroup })
export const setUser = user => ({ type: SET_USER, payload: user })
