import '../css/table.css';
import './UserDetail.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider, setUser } from '../actions';
import AddFromListPopup from '../components/AddFromListPopup';
import CircleButton from '../components/CircleButton';
import DeletePopup from '../components/DeletePopup';

var moment = require("moment")

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
    user: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
    setUser: user => dispatch(setUser(user)),
  }
}

class ConnectedUserDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      members: {},
      rooms: [],
      availableNumbers: {},
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("UserDetail.componentDidMount")
    this.props.setNavigation({ provider: true, account: true, user: true })
    let userUuid = this.props.computedMatch.params.userUuid
    if (this.props.user.uuid !== userUuid) {
      this.props.setUser({ uuid: "", name: "", email: "", resources: { numbers: [] } })
    }
    this.loadUser(userUuid)
    this.loadRooms(userUuid)
    if (this.props.account.uuid) {
      this.loadAvailableAccountNumbers()
    }
  }

  handleEditClick = () => {
    console.log(`UserDetail.handleEditClick()`)
    let userUuid = this.props.computedMatch.params.userUuid
    this.props.history.push({
      pathname: `/user/${userUuid}/edit`,
    })
  }

  handleDeleteUser = () => {
    console.log(`UserDetail.handleDeleteUser()`)
    this.foreman.delete_user(this.props.user.uuid).then(() => {
      this.props.history.push({
        pathname: `/account/${this.props.account.uuid}`,
      })
    })
  }

  loadProvider = providerUuid => {
    console.log(`UserDetail.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`UserDetail.loadAccount(${accountUuid})`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      if (self.props.provider.uuid !== account.provider) {
        self.loadProvider(account.provider)
      }
      self.loadAvailableAccountNumbers()
    })
  }

  loadAvailableAccountNumbers = () => {
    console.log(`UserDetail.loadAccountNumbers()`)
    let self = this
    this.foreman.get_account_numbers(this.props.account.uuid).then(retNumbers => {
      let numbers = {}
      for (let number of retNumbers) {
        if (number.resource.type === "None") {
          numbers[`+${number.cc}-${number.number}`] = number
        }
      }
      self.setState({ availableNumbers: numbers })
    })
  }

  loadUser = userUuid => {
    console.log(`UserDetail.loadUser(${userUuid})`)
    let self = this
    this.foreman.get_user(userUuid).then(user => {
      self.props.setUser(user)
      if (this.props.account.uuid !== user.account) {
        this.loadAccount(user.account)
      }
    })
  }

  loadRooms = userUuid => {
    console.log(`UserDetail.loadRooms(${userUuid})`)
    let self = this
    this.foreman.get_user_rooms(userUuid).then(rooms => {
      self.setState({ rooms: rooms })
    })
  }

  handleNewResourceClick = numberKeys => {
    console.log("UserDetail.handleNewResourceClick")
    const promises = []
    for (const numberKey of numberKeys) {
      promises.push(this.foreman.set_number_resource(numberKey, this.props.user.uuid, "User"))
    }
    Promise.all(promises).then(() => {
      this.loadUser(this.props.user.uuid)
      this.loadAvailableAccountNumbers()
    })
  }

  handleRemoveResourceClick = numberKey => {
    console.log("UserDetail.handleNewResourceClick")
    this.foreman.set_number_resource(numberKey, undefined, "None").then(() => {
      this.loadUser(this.props.user.uuid)
      this.loadAvailableAccountNumbers()
    })
  }

  render() {
    let user = this.props.user
    let resources = user.resources.numbers.map((number, idx) => (
      <div key={idx} className="tbl-row">
        <div className="tbl-cell resource-number">
          <FontAwesomeIcon icon="mobile-alt" />+{number.cc}-{number.number}
        </div>
        <div className="tbl-cell btn-remove">
          <CircleButton
            className="red btn-small"
            iconName="minus"
            onClick={() => this.handleRemoveResourceClick(`+${number.cc}-${number.number}`)}
          />
        </div>
      </div>
    ))
    const resourceOptions = []
    for (const key of Object.keys(this.state.availableNumbers)) {
      resourceOptions.push({ name: key, value: key })
    }

    let rooms = this.state.rooms.map((room, idx) => (
      <div key={room.uuid} className="tbl-row room">
        <div className="tbl-cell">{room.name}</div>
        <div className="tbl-cell">{room.type}</div>
      </div>
    ))

    return (
      <div id="user">
        {/* Header */}
        <div className="main-header">
          <div className="header">
            <h1>{user.name}</h1>
            <h2>User</h2>
          </div>
          <CircleButton className="red" iconName="pencil-alt" onClick={this.handleEditClick} />
          <DeletePopup name={user.name} type="User" impactedServices={{}} onDelete={this.handleDeleteUser} />
        </div>
        {/* Details */}
        <div className="details">
          <div className="detail id">
            <FontAwesomeIcon icon="fingerprint" /> {user.uuid}
          </div>
          <div className="detail created">
            <FontAwesomeIcon icon="calendar-plus" /> {moment(user.created).calendar()}
          </div>
          <div className="detail email">
            <FontAwesomeIcon icon="envelope" /> {user.email}
          </div>
        </div>
        {/* Resources */}
        <div className="section">
          <div className="main-header">
            <h1 className="header">Resources</h1>
            <AddFromListPopup type="Resource" options={resourceOptions} onAdd={this.handleNewResourceClick} />
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell" />
                </div>
              </div>
              <div className="tbl-body">{resources}</div>
            </div>
          </div>
        </div>
        {/* Rooms */}
        <div className="section">
          <div className="main-header">
            <h1 className="header">Rooms</h1>
          </div>
          <div>
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Name</div>
                  <div className="tbl-cell">Type</div>
                </div>
              </div>
              <div className="tbl-body">{rooms}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const UserDetail = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedUserDetail)
export default withRouter(UserDetail)
