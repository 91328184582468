import '../css/table.css';
import './BroadcastGroupForm.css';

import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setBroadcastGroup, setNavigation, setProvider } from '../actions';
import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
    broadcastGroup: state.broadcastGroup,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
    setBroadcastGroup: broadcastGroup => dispatch(setBroadcastGroup(broadcastGroup)),
  }
}

class ConnectedBroadcastGroupForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      header: "",
      btnClasses: "save",
      btnText: "",
      btnAction: null,
      users: {},
      f_name: "",
      f_use_signature: false,
      f_members: new Set(),
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentDidMount() {
    console.log("BroadcastGroupForm.componentDidMount")
    let accountUuid = this.props.computedMatch.params.accountUuid
    if (accountUuid) {
      this.loadAccount(accountUuid)
      this.props.setNavigation({ provider: true, account: true })
      this.setState({ type: "create", header: "New", btnText: "Create", btnAction: this.handleCreateBroadcastGroup })
    }
    let broadcastGroupUuid = this.props.computedMatch.params.broadcastGroupUuid
    if (broadcastGroupUuid) {
      this.loadBroadcastGroup(broadcastGroupUuid)
      this.props.setNavigation({ provider: true, account: true, broadcastGroup: true })
      this.setState({ type: "edit", header: "", btnText: "Update", btnAction: this.handleUpdateBroadcastGroup })
    }
  }

  loadProvider = providerUuid => {
    console.log(`BroadcastGroupForm.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`BroadcastGroupForm.loadAccount(${accountUuid})`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      if (self.props.provider.uuid !== account.provider) {
        self.loadProvider(account.provider)
      }
      this.loadUsers()
    })
  }

  loadBroadcastGroup = broadcastGroupUuid => {
    console.log(`BroadcastGroupDetail.loadBroadcastGroup(${broadcastGroupUuid})`)
    let self = this
    this.foreman.get_broadcast_group(broadcastGroupUuid).then(broadcastGroup => {
      self.props.setBroadcastGroup(broadcastGroup)
      self.setState({
        header: broadcastGroup.name,
        f_name: broadcastGroup.name,
        f_use_signature: broadcastGroup.use_signature || false,
      })
      if (broadcastGroup.account !== self.props.account.uuid) {
        self.loadAccount(broadcastGroup.account)
      }
    })
  }

  loadUsers = () => {
    console.log(`BroadcastGroupForm.loadUsers()`)
    let self = this
    this.foreman.get_users(this.props.account.uuid).then(retUsers => {
      let users = self.state.users
      for (let user of retUsers) {
        users[user.uuid] = user
      }
      self.setState({ users: users })
    })
  }

  handleCreateBroadcastGroup = event => {
    console.log("BroadcastGroupForm.handleCreateBroadcastGroup")
    this.setState({ processing: true, btnClasses: "spinner" })
    let providerUuid = this.props.provider.uuid
    let accountUuid = this.props.computedMatch.params.accountUuid
    if (providerUuid && accountUuid && this.state.f_name) {
      let f_members = this.state.f_members
      console.log(
        `Creating BroadcastGroup >> Provider: ${providerUuid} Account: ${accountUuid} Name: ${this.state.f_name}`,
      )
      this.foreman
        .create_broadcast_group(providerUuid, accountUuid, this.state.f_name, this.state.f_use_signature || false)
        .then(broadcastGroup => {
          console.log(`Setting BroadcastGroup Member >> BroadcastGroup: ${broadcastGroup.uuid} Members: ${f_members}`)
          this.foreman.set_broadcast_group_members(broadcastGroup.uuid, [...f_members]).then(members => {
            this.props.history.push({
              pathname: `/broadcast-group/${broadcastGroup.uuid}`,
            })
          })
        })
    } else {
      console.error("BroadcastGroupForm.handleCreateAccount empty providerUuid or name")
    }
  }

  handleUpdateBroadcastGroup = event => {
    console.log("BroadcastGroupForm.handleUpdateBroadcastGroup")
    this.setState({ processing: true, btnClasses: "spinner" })
    let broadcastGroupUuid = this.props.computedMatch.params.broadcastGroupUuid
    if (broadcastGroupUuid && this.state.f_name) {
      console.log(`Updating BroadcastGroup >> Broadcast Group: ${broadcastGroupUuid} Name: ${this.state.f_name}`)
      this.foreman
        .update_broadcast_group(broadcastGroupUuid, this.state.f_name, this.state.f_use_signature || false)
        .then(broadcastGroup => {
          this.loadBroadcastGroup(broadcastGroupUuid)
          this.props.history.push({
            pathname: `/broadcast-group/${broadcastGroupUuid}`,
          })
        })
    } else {
      console.error("BroadcastGroupForm.handleUpdateAccount empty name")
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleCheckboxChange = event => {
    const target = event.target
    const checked = target.checked
    const name = target.name
    console.log(target, checked, name)
    this.setState({
      [name]: checked,
    })
  }

  handleMembersChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    if (name !== "f_members") {
      return
    }

    let f_members = this.state.f_members
    if (target.checked) {
      if (!f_members.has(value)) {
        f_members.add(value)
        this.setState({
          f_members: f_members,
        })
      }
    } else {
      if (f_members.has(value)) {
        f_members.delete(value)
        this.setState({
          f_members: f_members,
        })
      }
    }
  }

  toggleMember = userUuid => event => {
    console.log(`BroadcastGroupForm.toggleMember(${userUuid})`)
    let checkbox = event.target
    if (event.target.tagName !== "INPUT") {
      checkbox = event.target.querySelector("input")
    }
    let update = false
    let f_members = this.state.f_members
    if (!checkbox.checked && !f_members.has(userUuid)) {
      f_members.add(userUuid)
      update = true
    } else if (f_members.has(userUuid)) {
      f_members.delete(userUuid)
      update = true
    }
    if (update) {
      this.setState({
        f_members: f_members,
      })
    }
  }

  render() {
    let self = this
    let users = Object.keys(this.state.users).map((key, idx) => {
      let user = self.state.users[key]
      let checked = self.state.f_members.has(user.uuid)
      return (
        <div key={user.uuid} className="tbl-row">
          <div className="tbl-cell" onClick={self.toggleMember(user.uuid)}>
            <input
              name="f_members"
              type="checkbox"
              checked={checked}
              value={user.uuid}
              onChange={self.handleMembersChange}
            />{" "}
            {user.name}
          </div>
        </div>
      )
    })

    let usersSection = (
      <div className="users">
        <div className="tbl">
          <div className="tbl-header">
            <div className="tbl-row">
              <div className="tbl-cell">Users</div>
            </div>
          </div>
          <div className="tbl-body">{users}</div>
        </div>
      </div>
    )

    return (
      <div id="broadcast-group-form">
        <div className="main-header">
          <div className="header">
            <h1>{this.state.header}</h1>
            <h2>Broadcast Group</h2>
          </div>
        </div>
        <div className="form">
          <FormInput info="Name" name="f_name" value={this.state.f_name} onChange={this.handleInputChange} />
          <div className="form-checkbox">
            <input
              name="f_use_signature"
              type="checkbox"
              checked={this.state.f_use_signature}
              onChange={this.handleCheckboxChange}
            />{" "}
            Use Signatures on Messages
          </div>
          {this.state.type === "create" ? usersSection : null}
          <div className="form-actions">
            <div className="form-fill" />
            <FormButton
              text={this.state.btnText}
              icon={this.state.btnClasses}
              disabled={this.state.processing}
              pulse={this.state.processing}
              onClick={this.state.btnAction}
            />
          </div>
        </div>
      </div>
    )
  }
}

const BroadcastGroupForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedBroadcastGroupForm)
export default withRouter(BroadcastGroupForm)
