import '../css/table.css';
import './UserForm.css';

import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider, setUser } from '../actions';
import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
    user: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
    setUser: user => dispatch(setUser(user)),
  }
}

class ConnectedUserForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      processing: false,
      btnClasses: "save",
      btnText: "",
      btnAction: null,
      header: "",
      broadcastGroups: {},
      f_name: "",
      f_email: "",
      f_password: "",
      f_broadcastGroups: new Set(),
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("UserForm.componentWillMount")
    let accountUuid = this.props.computedMatch.params.accountUuid
    let userUuid = this.props.computedMatch.params.userUuid
    if (userUuid) {
      this.props.setNavigation({ provider: true, account: true, user: true })
      this.setState({ type: "edit", header: "", btnText: "Update", btnAction: this.handleUpdateUser })
      this.loadUser(userUuid)
      this.loadRooms(userUuid)
    } else if (accountUuid) {
      this.props.setNavigation({ provider: true, account: true })
      this.setState({ type: "create", header: "New", btnText: "Create", btnAction: this.handleCreateUser })
      this.loadAccount(accountUuid)
    }
    this.loadGroups(this.props.account.uuid)
  }

  componentDidUpdate(prevProps) {
    if (this.props.account.uuid !== prevProps.account.uuid) {
      this.loadGroups(this.props.account.uuid)
    }
  }

  handleCreateUser = () => {
    console.log("UserForm.handleCreateUser")
    let self = this
    this.setState({ processing: true, btnClasses: "spinner" })
    let providerUuid = this.props.provider.uuid
    let accountUuid = this.props.account.uuid
    if (providerUuid && accountUuid && this.state.f_name && this.state.f_email && this.state.f_password) {
      console.log(`Creating User >> Provider: ${providerUuid} Account: ${accountUuid} Name: ${this.state.f_name}`)
      this.foreman
        .create_user(providerUuid, accountUuid, this.state.f_name, this.state.f_email, this.state.f_password)
        .then(user => {
          for (let broadcastGroupUuid of self.state.f_broadcastGroups) {
            console.log(`Setting BroadcastGroup Member >> BroadcastGroup: ${broadcastGroupUuid} Member: ${user.uuid}`)
            self.foreman.add_broadcast_group_members(broadcastGroupUuid, [user.uuid])
          }
          self.props.history.push({
            pathname: `/user/${user.uuid}`,
          })
        })
    } else {
      console.error("UserForm.handleCreateAccount empty providerUuid or name")
    }
  }

  handleUpdateUser = () => {
    console.log("UserForm.handleUpdateUser")
    let self = this
    this.setState({ processing: true, btnClasses: "spinner" })
    let userUuid = this.props.computedMatch.params.userUuid
    if (userUuid && this.state.f_name && this.state.f_email) {
      console.log(`Updating User >> UUID: ${userUuid}`)
      let data = {
        name: this.state.f_name,
        email: this.state.f_email,
      }
      if (this.state.f_password) {
        data["password"] = this.state.f_password
      }
      this.foreman.update_user(userUuid, data).then(() => {
        for (let broadcastGroupUuid of self.state.f_broadcastGroups) {
          console.log(`Setting BroadcastGroup Member >> BroadcastGroup: ${broadcastGroupUuid} Member: ${userUuid}`)
          self.foreman.add_broadcast_group_members(broadcastGroupUuid, [userUuid])
        }
        Object.keys(self.state.broadcastGroups).forEach(key => {
          let broadcastGroup = self.state.broadcastGroups[key]
          if (!self.state.f_broadcastGroups.has(broadcastGroup.uuid)) {
            console.log(`Removing BroadcastGroup Member >> BroadcastGroup: ${broadcastGroup.uuid} Member: ${userUuid}`)
            self.foreman.delete_broadcast_group_member(broadcastGroup.uuid, userUuid)
          }
        })
        self.props.history.push({
          pathname: `/user/${userUuid}`,
        })
      })
    } else {
      console.error("UserForm.handleCreateAccount empty providerUuid or name")
    }
  }

  loadProvider = providerUuid => {
    console.log(`UserForm.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`UserForm.loadAccount()`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      if (self.props.provider.uuid !== account.provider) {
        self.loadProvider(account.provider)
      }
    })
  }

  loadUser = userUuid => {
    console.log(`UserForm.loadUser(${userUuid})`)
    let self = this
    this.foreman.get_user(userUuid).then(user => {
      self.props.setUser(user)
      self.setState({ header: user.name, f_name: user.name, f_email: user.email, f_password: "" })
      if (this.props.account.uuid !== user.account) {
        this.loadAccount(user.account)
      }
    })
  }

  loadRooms = userUuid => {
    console.log(`UserForm.loadRooms(${userUuid})`)
    let self = this
    this.foreman.get_user_rooms(userUuid).then(rooms => {
      let f_broadcastGroups = self.state.f_broadcastGroups
      for (let room of rooms) {
        if (room.type === "BroadcastGroup") {
          f_broadcastGroups.add(room.uuid)
        }
      }
      self.setState({ f_broadcastGroups: f_broadcastGroups })
    })
  }

  loadGroups = accountUuid => {
    console.log(`UserForm.loadGroups(${accountUuid})`)
    if (!accountUuid) {
      console.warn("UserForm.loadGroups empty accountUuid")
      return
    }
    let self = this
    this.foreman.get_broadcast_groups(accountUuid).then(retBroadcastGroups => {
      let broadcastGroups = self.state.broadcastGroups
      for (let bgroup of retBroadcastGroups) {
        broadcastGroups[bgroup.uuid] = bgroup
      }
      self.setState({ broadcastGroups: broadcastGroups })
    })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleBroadcastGroupsChange = event => {
    console.log(`UserForm.handleBroadcastGroupsChange()`)
    const target = event.target
    const value = target.value
    const name = target.name

    if (name !== "f_broadcastGroups") {
      return
    }

    let f_broadcastGroups = this.state.f_broadcastGroups
    if (target.checked) {
      if (!f_broadcastGroups.has(value)) {
        f_broadcastGroups.add(value)
        this.setState({
          f_broadcastGroups: f_broadcastGroups,
        })
      }
    } else {
      if (f_broadcastGroups.has(value)) {
        f_broadcastGroups.delete(value)
        this.setState({
          f_broadcastGroups: f_broadcastGroups,
        })
      }
    }
  }

  toggleMember = broadcastGroupUuid => event => {
    console.log(`UserForm.toggleMember(${broadcastGroupUuid})`)
    let checkbox = event.target
    if (event.target.tagName !== "INPUT") {
      checkbox = event.target.querySelector("input")
    }
    let update = false
    let f_broadcastGroups = this.state.f_broadcastGroups
    if (!checkbox.checked && !f_broadcastGroups.has(broadcastGroupUuid)) {
      f_broadcastGroups.add(broadcastGroupUuid)
      update = true
    } else if (f_broadcastGroups.has(broadcastGroupUuid)) {
      f_broadcastGroups.delete(broadcastGroupUuid)
      update = true
    }
    if (update) {
      this.setState({
        f_broadcastGroups: f_broadcastGroups,
      })
    }
  }

  render() {
    let self = this
    let bgroups = Object.keys(this.state.broadcastGroups).map((key, idx) => {
      let bgroup = self.state.broadcastGroups[key]
      let checked = self.state.f_broadcastGroups.has(bgroup.uuid)
      return (
        <div key={bgroup.uuid} className="tbl-row">
          <div className="tbl-cell" onClick={self.toggleMember(bgroup.uuid)}>
            <input
              name="f_broadcastGroups"
              type="checkbox"
              checked={checked}
              value={bgroup.uuid}
              onChange={self.handleBroadcastGroupsChange}
            />{" "}
            {bgroup.name}
          </div>
        </div>
      )
    })

    return (
      <div id="user-form">
        <div className="main-header">
          <div className="header">
            <h1>{this.state.header}</h1>
            <h2>User</h2>
          </div>
        </div>
        <div className="form">
          <FormInput info="Name" name="f_name" value={this.state.f_name} onChange={this.handleInputChange} />
          <FormInput info="E-mail" name="f_email" value={this.state.f_email} onChange={this.handleInputChange} />
          <FormInput
            info="Password"
            type="password"
            name="f_password"
            value={this.state.f_password}
            onChange={this.handleInputChange}
          />
          <div className="broadcast-groups">
            <div className="tbl">
              <div className="tbl-header">
                <div className="tbl-row">
                  <div className="tbl-cell">Broadcast Groups</div>
                </div>
              </div>
              <div className="tbl-body">{bgroups}</div>
            </div>
          </div>
          <div className="form-actions">
            <div className="form-fill" />
            <FormButton
              text={this.state.btnText}
              icon={this.state.btnClasses}
              disabled={this.state.processing}
              pulse={this.state.processing}
              onClick={this.state.btnAction}
            />
          </div>
        </div>
      </div>
    )
  }
}

const UserForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedUserForm)
export default withRouter(UserForm)
