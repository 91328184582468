import { format } from 'date-fns';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider } from '../actions';
import ReportView from '../components/ReportView';
import Foreman from 'foreman-api';

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
  }
}

const columns = [
  {
    field: "date",
    headerName: "Date",
    sortDirection: "desc",
    type: "date",
    width: 200,
		valueGetter: ({value}) => new Date(value),
		valueFormatter: ({value}) => format(value, 'P p')
  },
  { field: "account", headerName: "Account", width: 200 },
  { field: "username", headerName: "Username", width: 200 },
  { field: "useremail", headerName: "User Email", width: 200 },
  { field: "groupname", headerName: "Group Name", width: 200 },
  { field: "internal", headerName: "Internal", width: 200 },
  { field: "external", headerName: "External", width: 200 },
  { field: "direction", headerName: "Direction", width: 200 },
  { field: "status", headerName: "Status", width: 200 },
]

const filterMap = {
  thirty: 30,
  sixty: 60,
  ninety: 90,
  custom: "custom",
  all: "all",
}

const dropDownOptions = [
  {
    label: "Past 30 Days",
    value: "thirty",
  },
  {
    label: "Past 60 Days",
    value: "sixty",
  },
  {
    label: "Past 90 Days",
    value: "ninety",
  },
  {
    label: "Custom",
    value: "custom",
  },
  {
    label: "All",
    value: "all",
  },
]
class MessageAudit extends Component {
  constructor(props) {
    super(props)
    const providerId = props.computedMatch.params.providerUuid
    this.state = {
      providerId,
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentDidMount() {
    console.log("MessageAudit.componentDidMount")
    this.props.setNavigation({ provider: true })
    this.loadProvider(this.props.computedMatch.params.providerUuid)
  }

  loadProvider = providerUuid => {
    console.log(`MessageAudit.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  render() {
    return (
      <ReportView
        columns={columns}
        title={"Message Audit"}
        filterMap={filterMap}
        dropDownOptions={dropDownOptions}
        durationFilter={dropDownOptions.find(x => x.value === "all")}
        fetchUrl={"download_provider_message_report"}
        fileName={"_Message_Report.csv"}
        providerId={this.state.providerId}
        headerType={"Provider"}
      />
    )
  }
}

const MessageAuditComp = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageAudit)
export default withRouter(MessageAuditComp)
