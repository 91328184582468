import './AddFromListPopup.css';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';

import CircleButton from './CircleButton';
import DialogTitle from './DialogTitle';
import FormButton from './FormButton';

export default function AddFromListPopup(props) {
  const [open, setOpen] = useState(false)
  const [selectedValues, setSelectedValues] = useState(new Set())

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleAdd = () => {
    props.onAdd(Array.from(selectedValues))
    handleClose()
  }

  const handleChange = event => {
    console.log(`AddFromListPopup.handleChange`)
    const value = event.target.value
    const checked = event.target.checked

    let values = new Set(selectedValues)
    if (checked && !values.has(value)) {
      values.add(value)
      setSelectedValues(values)
    } else if (!checked && values.has(value)) {
      values.delete(value)
      setSelectedValues(values)
    }
  }

  const listOfItems = () => {
    return props.options.map((option, index) => {
      let checked = selectedValues.has(option.value)
      return (
        <div key={index}>
          <input name="add-from-list" type="checkbox" checked={checked} value={option.value} onChange={handleChange} />
          {" " + option.name}
        </div>
      )
    })
  }

  return (
    <div>
      <CircleButton iconName="plus" onClick={handleOpen} />
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Add {props.type + "(s)"}
        </DialogTitle>
        <MuiDialogContent dividers>
          {props.options &&
            props.options.length === 0 && <Typography gutterBottom>No {props.type}s available to add.</Typography>}
          {props.options && props.options.length > 0 && listOfItems()}
        </MuiDialogContent>
        {props.options &&
          props.options.length > 0 && (
            <FormButton icon="plus" className="btn-white" onClick={() => handleAdd(close)}>
              Add {props.type + "(s)"}
            </FormButton>
          )}
      </Dialog>
    </div>
  )
}
