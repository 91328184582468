import '../css/table.css';
import './NumberForm.css';

import Foreman from 'foreman-api';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { setAccount, setNavigation, setProvider } from '../actions';
import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';
import FormSelect from '../components/FormSelect';

const ccRegex = /^\d{1,3}$/g
const phoneRegex = /^[2-9]\d\d[2-9]\d{6}$/g

const mapStateToProps = state => {
  return {
    provider: state.provider,
    account: state.account,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNavigation: navigation => dispatch(setNavigation(navigation)),
    setProvider: provider => dispatch(setProvider(provider)),
    setAccount: account => dispatch(setAccount(account)),
  }
}

class ConnectedNumberForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      callbacks: {},
      processing: false,
      btn_classes: "save",
      f_cc: "",
      f_number: "",
      f_callback: "",
      f_cc_valid: true,
      f_number_valid: true,
      submitted: false,
      error: "",
    }
    this.foreman = Foreman.getInstance(process.env.REACT_APP_FOREMAN_API_URI)
  }

  componentWillMount() {
    console.log("NumberForm.componentWillMount")
    let accountUuid = this.props.computedMatch.params.accountUuid
    this.props.setNavigation({ provider: true, account: true })
    this.loadAccount(accountUuid)
  }

  loadProvider = providerUuid => {
    console.log(`NumberForm.loadProvider(${providerUuid})`)
    let self = this
    this.foreman.get_provider(providerUuid).then(provider => {
      self.props.setProvider(provider)
    })
  }

  loadAccount = accountUuid => {
    console.log(`NumberForm.loadAccount(${accountUuid})`)
    let self = this
    this.foreman.get_account(accountUuid).then(account => {
      self.props.setAccount(account)
      if (account.provider !== self.props.provider.uuid) {
        self.loadProvider(account.provider)
      }
      self.loadCallbacks(account.provider)
    })
  }

  loadCallbacks = async providerUuid => {
    console.log(`NumberForm.loadCallbacks(${providerUuid})`)
    const [bandwidths, telnyxs] = await Promise.all([
      this.foreman.get_bandwidth_callbacks(providerUuid),
      this.foreman.get_telnyx_callbacks(providerUuid),
    ])
    const callbacks = {}
    for (const callback of bandwidths) {
      callbacks[callback.uuid] = { ...callback, name: callback.application_id, type: "Bandwidth" }
    }
    for (const callback of telnyxs) {
      callbacks[callback.uuid] = { ...callback, name: callback.uuid, type: "Telnyx" }
    }
    this.setState({ callbacks: callbacks })
  }

  handleCreateNumber = async event => {
    console.log("NumberForm.handleCreateNumber")
    console.log(this.state.f_cc, this.state.f_number, this.state.f_callback)
    this.setState({ submitted: true, processing: true, btn_classes: "spinner" })
    let invalid = false
    if (!this.state.f_cc || !this.state.f_cc.match(ccRegex)) {
      this.setState({ f_cc_valid: false })
      invalid = true
    }
    if (!this.state.f_number || !this.state.f_number.match(phoneRegex)) {
      this.setState({ f_number_valid: false })
      invalid = true
    }
    if (!this.state.f_callback) {
      invalid = true
    }
    if (invalid) {
      console.error("NumberForm.handleCreateNumber invalid input")
      this.setState({ processing: false, btn_classes: "save" })
      return
    }

    let providerUuid = this.props.provider.uuid
    let accountUuid = this.props.account.uuid
    let cc = this.state.f_cc
    let number = this.state.f_number
    let callback = this.state.callbacks[this.state.f_callback]
    console.log(
      `Creating Account >> Provider: ${providerUuid} Account: ${accountUuid} Number: +${cc}-${number} Callback: ${
        callback.uuid
      }`,
    )
    try {
      await this.foreman.create_number(
        providerUuid,
        accountUuid,
        Number.parseInt(cc, 10),
        number,
        callback.type,
        callback.uuid,
      )
      this.props.history.push({ pathname: `/account/${accountUuid}` })
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        this.setState({ error: e.response.data.message })
      }
      console.error("Request failed" + JSON.stringify(e))
      this.setState({ processing: false, btn_classes: "save" })
    }
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const validityName = target.name + "_valid"
    this.setState({ [name]: value, [validityName]: true })
  }

  render() {
    const callbackOptions = Object.keys(this.state.callbacks).map(key => {
      let callback = this.state.callbacks[key]
      return { name: callback.type + ": " + callback.name, value: callback.uuid }
    })
    let error
    if (this.state.error) {
      error = <p className="error">{this.state.error}</p>
    }

    return (
      <div id="number-form">
        <div className="main-header">
          <div className="header">
            <h1>New</h1>
            <h2>Number</h2>
          </div>
        </div>
        <div className="form d-flex flex-column">
          {error}
          <div className="d-flex">
            <FormInput
              className={"w-10" + (this.state.f_cc_valid ? "" : " invalid")}
              info="CC"
              name="f_cc"
              value={this.state.f_cc}
              onChange={this.handleInputChange}
            />
            <FormInput
              className={"flex-grow" + (this.state.f_number_valid ? "" : " invalid")}
              info="Number"
              name="f_number"
              value={this.state.f_number}
              onChange={this.handleInputChange}
            />
          </div>
          <FormSelect
            className={this.state.submitted && !this.state.f_callback ? "invalid" : ""}
            info="Callback"
            name="f_callback"
            value={this.state.f_callback}
            options={callbackOptions}
            onChange={this.handleInputChange}
          />
          <div className="form-actions">
            <div className="form-fill" />
            <FormButton
              text="Create"
              icon={this.state.btn_classes}
              disabled={this.state.processing}
              pulse={this.state.processing}
              onClick={this.handleCreateNumber}
            />
          </div>
        </div>
      </div>
    )
  }
}

const NumberForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedNumberForm)
export default withRouter(NumberForm)
